import React, { useEffect, useState } from 'react';
import { BsFillArchiveFill, BsFillGrid3X3GapFill, BsPeopleFill } from 'react-icons/bs';
import { SlEnergy } from "react-icons/sl";
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Mapa from '../Componentes/Mapa';
import Form from 'react-bootstrap/Form';

function Home() {
    const [temperatura, setTemperatura] = useState('');
    const [humedad, setHumedad] = useState('');
    const [iluminacion, setIluminacion] = useState('');
    const [latitud, setLatitud] = useState('');
    const [longitud, setLongitud] = useState('');
    const [bateria, setBateria] = useState('');

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null); // Estado para almacenar la fecha seleccionada
    const [selectedOption, setSelectedOption] = useState(''); // Estado para almacenar la opción seleccionada del combo box
    const [trackers, setTrackers] = useState([]); // Estado para almacenar los datos de los trackers

    useEffect(() => {
        // Realizar la solicitud a la API para obtener los datos de trackers
        fetch('https://rfid.edgarleal.es/Api/Web/trackers.php')
            .then(response => response.json())
            .then(data => {
                setTrackers(data);
                if (data.length > 0) {
                    // Seleccionar el primer tracker por defecto si hay trackers disponibles
                    setSelectedOption(data[0].idTrackers);
                }
            })
            .catch(error => console.error('Error fetching trackers data:', error));
    }, []);

    useEffect(() => {
        // Función para cargar los datos del gráfico y del tracker seleccionado
        const fetchData = async () => {
            if (selectedOption) {
                try {
                    // Obtener datos del gráfico
                    const responseGrafico = await fetch(`https://rfid.edgarleal.es/Api/Web/datosGrafico.php?id=${selectedOption}`);
                    if (!responseGrafico.ok) {
                        throw new Error('Error al cargar los datos del gráfico');
                    }
                    const jsonData = await responseGrafico.json();
                    const formattedData = jsonData.temperaturas.map((temp, index) => ({
                        name: new Date(temp.fechatemperatura).getTime(),
                        temperatura: parseFloat(temp.temperatura),
                        humedad: parseFloat((jsonData.humedades[index].humedad * 100).toFixed(1)),
                        fecha: new Date(temp.fechatemperatura)
                    }));
                    setData(formattedData);

                    // Obtener datos de temperatura, humedad, iluminación y batería del tracker
                    const responseTracker = await fetch(`https://rfid.edgarleal.es/Api/Web/datosTracker.php?id=${selectedOption}`);
                    if (!responseTracker.ok) {
                        throw new Error('Error al cargar los datos del tracker');
                    }
                    const trackerData = await responseTracker.json();
                    setTemperatura(trackerData.temperatura);
                    setHumedad(trackerData.humedad);
                    setIluminacion(trackerData.iluminacion);
                    setLatitud(trackerData.latitud);
                    setLongitud(trackerData.longitud);
                    setBateria(trackerData.bateria); // Agregar el dato de batería

                    setIsLoading(false);
                } catch (error) {
                    setError(error.message);
                    setIsLoading(false);
                }
            }
        };

        fetchData();
    }, [selectedOption]);

    // Función para manejar el cambio de fecha seleccionada
    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
    };

    // Función para manejar el cambio de opción seleccionada en el combo box
    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    // Filtrar los datos por fecha seleccionada
    const filteredData = selectedDate ? data.filter(item => {
        const itemDate = new Date(item.name);
        const selectedDateTime = new Date(selectedDate);
        return itemDate.toDateString() === selectedDateTime.toDateString();
    }) : data;

    return (
        <main className='main-container'>

            {/* Combo box para la opción */}
            <div className='option-selector'>
                <label htmlFor="option">Seleccione el número de container: </label>

                <Form.Select aria-label="Default select example" id="option" value={selectedOption} onChange={handleOptionChange}>
                    {trackers.map(tracker => (
                        <option key={tracker.idTrackers} value={tracker.idTrackers}>{tracker.ncontainer}</option>
                    ))}
                </Form.Select>
            </div>
            <p></p>

            <Mapa selectedTrackerId={selectedOption} latitud={latitud} longitud={longitud} />

            <div className='main-title'>
                <h3>DASHBOARD</h3>
            </div>

            <div className='main-cards'>
                <div className='card'>
                    <div className='card-inner'>
                        <h3>Temperatura</h3>
                        <BsFillArchiveFill className='card_icon' />
                    </div>
                    <h1>{temperatura} °C</h1>
                </div>
                <div className='card'>
                    <div className='card-inner'>
                        <h3>Humedad</h3>
                        <BsFillGrid3X3GapFill className='card_icon' />
                    </div>
                    <h1>{humedad * 100}%</h1>
                </div>
                <div className='card'>
                    <div className='card-inner'>
                        <h3>Container</h3>
                        <BsPeopleFill className='card_icon' />
                    </div>
                    <h1>{iluminacion}</h1>
                </div>
                <div className='card'>
                    <div className='card-inner'>
                        <h3>Batería</h3>
                        <SlEnergy className='card_icon'/>
                    </div>
                    <h1>{bateria}</h1>
                </div>
            </div>

            {/* Selector de fecha */}
            <div className='date-selector'>
                <label htmlFor="date">Seleccione una fecha:</label>
                <input type="date" id="date" value={selectedDate} onChange={handleDateChange} />
            </div>

            {/* Gráfico */}
            <div className='charts'>
                <ResponsiveContainer width="200%" height={400}>
                    <LineChart
                        data={filteredData}
                        margin={{ top: 20, right: 30, left: 20, bottom: 10 }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            dataKey={data => new Date(data.name).toLocaleTimeString()}
                        />
                        <YAxis />
                        <Tooltip
                            content={({ active, payload, label }) => {
                                if (active && payload && payload.length) {
                                    const date = payload[0].payload.fecha;
                                    const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
                                    const formattedTime = `${date.getHours()}:${date.getMinutes().toString().padStart(2, '0')}`;
                                    return (
                                        <div className="custom-tooltip">
                                            <p className="label">{`Fecha: ${formattedDate}`}</p>
                                            <p className="label">{`Hora: ${formattedTime}`}</p>
                                            <p className="intro">{`Temperatura: ${payload[0].value} °C`}</p>
                                            <p className="intro">{`Humedad: ${payload[1].value}%`}</p>
                                        </div>
                                    );
                                }
                                return null;
                            }}
                            wrapperStyle={{ backgroundColor: 'white', borderRadius: '5px', padding: '5px', color: 'black' }}
                        />

                        <Legend />
                        <Line
                            type="monotone"
                            dataKey="temperatura"
                            stroke="#8884d8"
                            name="Temperatura (°C)"
                            dot={false}
                        />
                        <Line
                            type="monotone"
                            dataKey="humedad"
                            stroke="#82ca9d"
                            name="Humedad (%)"
                            dot={false}
                        />
                    </LineChart>

                </ResponsiveContainer>
            </div>
        </main>
    );
}

export default Home;
