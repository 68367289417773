import React from 'react';
import {
BsGlobeAmericas, BsGrid1X2Fill, BsFillArchiveFill, BsFillGrid3X3GapFill, BsPeopleFill,
    BsListCheck, BsBell, BsFillGearFill
}
from 'react-icons/bs';
import { IoIosAddCircleOutline } from "react-icons/io";

function Sidebar({ openSidebarToggle, OpenSidebar }) {
    return (
        <aside id="sidebar" className={openSidebarToggle ? "sidebar-responsive" : ""}>
            <div className='sidebar-title'>
                <div className='sidebar-brand'>
                    <BsGlobeAmericas className='icon_header' /> Tracker
                </div>
                <span className='icon close_icon' onClick={OpenSidebar}>X</span>
            </div>

            <ul className='sidebar-list'>
                <li className='sidebar-list-item'>
                    <a href="">
                        <BsGrid1X2Fill className='icon' /> Dashboard
                    </a>
                </li>
                
                <li className='sidebar-list-item'>
                    <a href="">
                        <IoIosAddCircleOutline className='icon' /> Agregar Tracker
                    </a>
                </li>
                
                <li className='sidebar-list-item'>
                    <a href="">
                        <BsBell className='icon' /> Alertas
                    </a>
                </li>
                {/*
                <li className='sidebar-list-item'>
                    <a href="">
                        <BsPeopleFill className='icon' /> Customers
                    </a>
                </li>
                <li className='sidebar-list-item'>
                    <a href="">
                        <BsListCheck className='icon' /> Inventory
                    </a>
                </li>
                <li className='sidebar-list-item'>
                    <a href="">
                        <BsMenuButtonWideFill className='icon' /> Reports
                    </a>
                </li>
                */}
                <li className='sidebar-list-item'>
                    <a href="">
                        <BsFillGearFill className='icon' /> Ajustes
                    </a>
                </li>
            </ul>
        </aside>
    )
}

export default Sidebar