import React, { useEffect, useState } from 'react';
import { GoogleMap, LoadScript, Marker, Polyline, InfoWindow  } from '@react-google-maps/api';

function Mapa({ selectedTrackerId }) {
    const [latitud, setLatitud] = useState('');
    const [longitud, setLongitud] = useState('');
    const [ubicaciones, setUbicaciones] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(null)

    useEffect(() => {
        // Realizar la solicitud a la API para obtener los datos del tracker
        if (selectedTrackerId) {
            fetch(`https://rfid.edgarleal.es/Api/Web/datosTracker.php?id=${selectedTrackerId}`)
                .then(response => response.json())
                .then(data => {
                    setLatitud(data.latitud);
                    setLongitud(data.longitud);
                })
                .catch(error => console.error('Error fetching data:', error));

            // Realizar la solicitud a la API para obtener la ruta del tracker
            fetch(`https://rfid.edgarleal.es/Api/Web/rutaTracker.php?id=${selectedTrackerId}`)
                .then(response => response.json())
                .then(data => {
                    setUbicaciones(data);
                })
                .catch(error => console.error('Error fetching data:', error));
        }
    }, [selectedTrackerId]);
    const containerStyle = {
        width: '100%',
        height: '400px'
    };

    const mapStyles = [
        {
            "featureType": "administrative",
            "elementType": "geometry",
            "stylers": [
                { "visibility": "off" }
            ]
        },
        {
            "featureType": "landscape",
            "elementType": "geometry",
            "stylers": [
                { "visibility": "off" }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
                { "visibility": "off" }
            ]
        },
        {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": [
                { "visibility": "on" }
            ]
        },
        {
            "featureType": "road",
            "elementType": "labels",
            "stylers": [
                { "visibility": "off" }
            ]
        },
        {
            "featureType": "transit",
            "elementType": "geometry",
            "stylers": [
                { "visibility": "on" }
            ]
        },
        {
            "featureType": "transit",
            "elementType": "labels",
            "stylers": [
                { "visibility": "off" }
            ]
        },
        {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
                { "visibility": "on" },
                { "color": "#12608d" }
            ]
        },
        {
            "featureType": "water",
            "elementType": "labels.text.fill",
            "stylers": [
                { "visibility": "off" }
            ]
        },
        {
            "featureType": "water",
            "elementType": "labels.text.stroke",
            "stylers": [
                { "visibility": "off" }
            ]
        }
    ];


    const center = {
        lat: parseFloat(latitud), // Convertir la latitud a número flotante
        lng: parseFloat(longitud) // Convertir la longitud a número flotante
    };

    const mapOptions = {
        disableDefaultUI: true, // Desactiva todos los controles predeterminados del mapa
        styles: [ // Estilos personalizados del mapa
            {
                featureType: 'poi', // Puntos de interés
                stylers: [{ visibility: 'off' }] // Oculta los puntos de interés
            }
        ]
    };

    const handleMouseOver = (ubicacion) => {
        setSelectedLocation(ubicacion);
    };

    const handleMouseOut = () => {
        setSelectedLocation(null);
    };


    return (
        <LoadScript
            googleMapsApiKey="AIzaSyAsSi7ZvaYZsRROGHAHwBkQx62-jfNXHCI" // Reemplaza 'TU_API_KEY' con tu propia clave de API de Google Maps
        >
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={12}
                options={mapOptions}
            >
                {ubicaciones.length > 0 && (
                    <Polyline
                        path={ubicaciones.map(ubicacion => ({ lat: parseFloat(ubicacion.latitud), lng: parseFloat(ubicacion.longitud) }))}
                        options={{
                            strokeColor: '#FF0000',
                            strokeOpacity: 0.8,
                            strokeWeight: 2,
                        }}
                        onMouseOver={() => handleMouseOver(ubicaciones)} // Aquí debería ser solo `handleMouseOver`
                        onMouseOut={handleMouseOut}
                    />


                )}

                {selectedLocation && (
                    <InfoWindow
                        position={{ lat: parseFloat(selectedLocation.latitud), lng: parseFloat(selectedLocation.longitud) }}
                        onCloseClick={handleMouseOut}
                    >
                        <div>{selectedLocation.fechaubicacion}</div>
                    </InfoWindow>
                )}  
            </GoogleMap>
        </LoadScript>
    );
}

export default Mapa;